<template>
  <div>
    <draggable 
      :list="proxyItems"
      draggable=".ranking"
    >
      <div 
        v-for="(item,i) in items"
        :key="i"
        class="ranking"
      >
        <div class="d-flex align-items-center">
          <div class="ranking-number mr-3">
            <div class="ranking-number-box">
              {{ i+1 }}
            </div>
          </div>
          <div class="ranking-goal">
            {{ item.goal }}
          </div>
        </div>
      </div>
    </draggable>
  </div>
</template>

<script>
  import draggable from 'vuedraggable'
  export default {
    components: {draggable},
    props: {
      items: {default: () => [], type: Array},
    },
    emits: ['change'],
    computed: {
      proxyItems: {
        get() {
          return this.items;
        },
        set (val) {
          this.$emit('change',val);
        }
      },
    },
  }
</script>
