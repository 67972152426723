require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
import Vue from 'vue/dist/vue.js'
var $ = require( 'jquery' )
import "controllers"
import 'bootstrap/dist/js/bootstrap'
import { Carousel3d, Slide } from 'vue-carousel-3d';
import caliradio from './components/cali-radio.vue'
import calicheckbox from './components/cali-checkbox.vue'
import goallist from './components/goal-list.vue'
import StarRating from 'vue-star-rating'
import rankinglist from './components/rankinglist.vue'
import "@fortawesome/fontawesome-free/js/all";
import './styles.scss'
import { library, icon } from '@fortawesome/fontawesome-svg-core'
import { faSync } from '@fortawesome/free-solid-svg-icons'
import Chart from 'chart.js/auto';
import "select2/dist/js/select2.min"
import 'jquery-toast-plugin';



library.add(faSync)
const isNotEmptyString = (value) => typeof(value) == 'string' && value.length > 0
function setupQuestions(){
  setupPopups();
  setupOnboarding();
  setupDataOnboarding();
  setupApply();
  setupAppraise();
  setupProblemStatement();
  setupGoalAlignment();
  setupCardSlider();
  setupShare();
  setupReportChecker();
  setupBehSciProceed();
}


/* Queries the report endpoint at a 3s interval and redirects once the report is ready */

function setupReportChecker() {
    if(document.querySelector('.report-ready')){
        disableBack();
        setInterval(function(){
            fetch("/check_report_status", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
            }).then(response => response.json()).then(data => {
                if(data.status == "ready"){
                    document.querySelector('.report-ready').style.display = 'block';
                    document.querySelector('.report-pending').style.display = 'none';
                }
            })
        }, 3000);
    }
}


/* Event handler for the share button */

function setupShare() {
    var shareTrigger = document.querySelector('.share-trigger')
    var sharePopup = document.querySelector('.share-overlay')
    var shareClose = document.querySelector('.share-popup-close')
    var shareSubmit = document.querySelector('.share-submit')
    var shareSkip = document.querySelector('.share-skip')

    if(shareTrigger){

        shareSkip.addEventListener('click', function(){

          /* Redirect based on the type of share */
          if(window.location.href.indexOf("data")>-1){
            window.location = '/data-onboarding'
          } else {
            window.location = "/goals"
          }
        });



        /* Visibility event handlers */
        shareTrigger.addEventListener('click', function(){
            shareSkip.style.display = 'none';
            sharePopup.style.display = "flex"
        });
        shareClose.addEventListener('click', function(){
            sharePopup.style.display = "none";
        });


        /* Validation for the share form */
        shareSubmit.addEventListener('click', function() {
            var firstName1 = document.querySelector('#shareFirstName1').value
            var lastName1 = document.querySelector('#shareLastName1').value
            var email1 = document.querySelector('#shareEmail1').value

            /* Conditionally validate the second set */
            if(document.querySelector('#shareFirstName2')){
                var firstName2 = document.querySelector('#shareFirstName2').value
                var lastName2 = document.querySelector('#shareLastName2').value
                var email2 = document.querySelector('#shareEmail2').value
            }

            /* Only one is mandatory */
            if(firstName1 == "" || lastName1 == "" || email1 == ""){
                alert("Please fill in all fields correctly.")
                return
            }
            shareSubmit.disabled = true
            fetch("/api/share_section", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ 
                    section_type: gon.section_type || "behsc",
                    first_name1: firstName1,
                    last_name1: lastName1,
                    email1: email1,
                    first_name2: firstName2,
                    last_name2: lastName2,
                    email2: email2,
                })
            }).then(response => {
                shareSubmit.disabled = false
                sharePopup.style.display = "none";
                if(window.location.href.indexOf("data")>-1){
                    window.location = '/progress/4'
                } else {
                    window.location = "/goals"
                }
            })
        });
    }
}


/* Event handler for share popup when the behsci proceed button is pressed */
function setupBehSciProceed() {
  if(document.querySelector('.behsci-proceed')){
    document.querySelector('.behsci-proceed').addEventListener('click',function(){
        if(gon.inviteMode == false){
            var sharePopup = document.querySelector('.share-overlay')
            var shareSkip = document.querySelector('.share-skip')
            shareSkip.style.display = 'inline-block';
            sharePopup.style.display = "flex"
        } else {
            window.location.href = "/goals";
        }
    })
  }

  if(document.querySelector('.data-proceed')){
    document.querySelector('.data-proceed').addEventListener('click',function(){
        if(gon.inviteMode == false){
            var sharePopup = document.querySelector('.share-overlay')
            var shareSkip = document.querySelector('.share-skip')
            shareSkip.style.display = 'inline-block';
            sharePopup.style.display = "flex"
        } else {
            window.location.href = "/data-onboarding";
        }
    })
  }

}


/* Event handling for the introductory popups for each section */
function setupPopups(){

    if(document.querySelector('.section-popup-app')){
        var sectionPopupApp = new Vue({
            el: ".section-popup-app",
            data: {
                page: 0,
                images: gon.images,
            },
            methods: {
                prevPage(){
                    if(this.page > 0){
                        this.page-=1;
                    }
                },
                nextPage(){
                    if(this.images.length > this.page+1) {
                        this.page+=1;
                    }
                },
            },
            mounted: function(){
                var showButton = document.querySelector('.show-popup')
                var overlay = document.querySelector('.popup-overlay')
                if(showButton) {
                    showButton.addEventListener('click', function(){
                        overlay.style.display = 'flex'
                    })
                }
                var closeButton = document.querySelector('.popup-close')
                if(closeButton){
                    closeButton.addEventListener('click', function(){
                        overlay.style.display = 'none'
                    })
                }
            }
        });
    }
}

/* Initialize 3D carousel */
function setupCardSlider() {
    Vue.use(Carousel3d);
    if(document.querySelector('.card-slider-app')){
        window.cardSliderApp = new Vue({
            el: ".card-slider-app",
            components: {
                Carousel3d,
                Slide
            },
            data: {
            }
        });
    }
}


/* Initialization and event handling for goal-alignment on-page interactivity*/
function setupGoalAlignment(){
    if(document.querySelector('.goal-alignment-app')){
        console.log("GoalAlignment");
        window.goalAlignmentApp = new Vue({
            el: ".goal-alignment-app",
            components: {
                goallist,
                StarRating,
            },
            data() {
                return {
                    goals: gon.answers == null || JSON.stringify(gon.answers) == "{}" ? [{goal: ""},{goal: ""},{goal: ""}] : gon.answers.goals,
                    submitting: false,
                    fiveStarReview: gon.answers == null || JSON.stringify(gon.answers) == "{}" ? "" : gon.answers.fiveStarReview,
                    oneStarReview: gon.answers == null || JSON.stringify(gon.answers) == "{}" ? "" : gon.answers.oneStarReview,
                    appStoreRating: gon.answers == null || JSON.stringify(gon.answers) == "{}" ? 0 : gon.answers.appStoreRating,
                    playStoreRating: gon.answers == null || JSON.stringify(gon.answers) == "{}" ? 0 : gon.answers.playStoreRating,
                }
            },
            methods: {
                validateSubmission(){
                    this.submitting = true;
                    console.log("Validating...");
                    var notBlank = ['fiveStarReview', 'oneStarReview', 'appStoreRating', 'playStoreRating'];
                    var valid = true;
                    var self = this;

                    if(!valid) {
                        alert("Please fill in all fields to continue");
                        this.submitting = false
                        return false;
                    }
                    if( this.goals.length < 3 || 
                        (this.goals.length - this.goals.filter(x => !isNotEmptyString(x.goal)).length) < 3) 
                    {
                        alert("Please fill in at least 3 goals to continue");
                        this.submitting = false
                        return false;
                    }

                    if( this.appStoreRating > 5 || this.appStoreRating < 0 || this.playStoreRating > 5 || this.playStoreRating < 0) {
                        alert("Please fill in store ratings between 0 and 5");
                        this.submitting = false;
                        return false;
                    }

                    /* Submit Goal Alignment Page - This is a unique page so it redirects to feedback/absorb */
                    fetch("/api/answer", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({ 
                          questionnaire_id: gon.questionnaire.id, 
                          answers: {
                            goals: this.goals,
                            oneStarReview: this.oneStarReview,
                            fiveStarReview: this.fiveStarReview,
                            appStoreRating: this.appStoreRating,
                            playStoreRating: this.playStoreRating
                          }
                        })
                    }).then(response => {
                        this.submitting = false
                        window.location = "/feedback/absorb";
                    });
                },
            }
        });
    }
}


/* Initialization for Problem Statement Unique Page event handling */
function setupProblemStatement(){
    if(document.querySelector('.problem-statement-app')){
        var rankingItems = gon.rankingProblems.map(x => x.title);
        var rankingItemsObject = gon.rankingProblems.map((x) => {return {goal: x.title, id: x.id};})
        console.log("ProblemStatement");
        window.problemStatementApp = new Vue({
            el: ".problem-statement-app",
            components: {
                rankinglist,
                caliradio,
            },
            data() {
                return {
                    items: rankingItemsObject,
                    immediateProblem: null,
                    metric: null,
                    dataNumber: null,
                }
            },
            methods: {

            },
        });
    }
}

/* Event handling for Appraise pages */
function setupAppraise(){
  var errorShown = false;
    var dataAppraise = document.querySelector('.data-appraise')
    if(dataAppraise){
        window.selectedProblems = []
        if(gon.attempt && gon.attempt.answers && gon.attempt.answers.microProblems){
            window.selectedProblems = gon.attempt.answers.microProblems;
        }
        document.querySelectorAll('.ranking-checkbox-holder').forEach(function(el){
            el.addEventListener('click', function(e){
              console.log("X")
                if(e.target.classList.contains('ranking-checkbox')){
                  console.log("Y")
                    return
                }
                el.querySelector('input').checked = !el.querySelector('input').checked
                el.querySelector('input').dispatchEvent(new Event('change'))
            })
        })

        document.querySelectorAll('.ranking-checkbox').forEach(function(el){
            el.addEventListener('change', function(){
                var clickedId = this.dataset.id
                if(this.checked){
                    selectedProblems.push(clickedId)
                    if(selectedProblems.indexOf(clickedId)>-1){
                        while(selectedProblems.length > 2){
                            var idToRemove = selectedProblems.shift()
                            document.querySelector(`.ranking-checkbox[data-id="${idToRemove}"]`).checked = false
                        }
                    }
                    console.log(selectedProblems)
                } else {
                    selectedProblems = selectedProblems.filter(x => x!= clickedId)
                    console.log(selectedProblems)
                }
            })
        })
    }

    /* This can probably be moved to another method */
    if(document.querySelector('.city-selector')){ 
      $.ajaxSetup({
        beforeSend: function( xhr ) {
          var token = $('meta[name="csrf-token"]').attr('content');
          if (token) xhr.setRequestHeader('X-CSRF-Token', token);
        }
      });
        $('.city-selector').select2({
            ajax: {
                url: "/cities",
                dataType: "json"
            }
        });
        if(gon.answers && gon.answers[$('.city-selector').data('question')]) {
            var answers = gon.answers[$('.city-selector').data('question')]
            answers.forEach(a => {
                var option = new Option(a.text, a.id, true, true);
                $('.city-selector').append(option).trigger('change')
                $('.city-selector').trigger('select2:select', {params: { data: a }});
            });
        }
    }
    if(document.querySelector('.demographics-next')){
        var nextButton = document.querySelector('.demographics-next')
        nextButton.addEventListener('click', function(){
            var url = this.dataset.url
            var answers = {}
            var errored = false;
            document.querySelectorAll('.validate').forEach(function(v,k){
                if(!errored){
                    var total = 0
                    v.querySelectorAll('input').forEach((x) => total+=parseInt(x.value || 0))
                    if(total != 100){
                        alert("Values for the question '" + v.dataset.label + "' must add up to 100%")
                        errored = true;
                        return;
                    }
                }
            });
            if(errored){
              return;
            }
            document.querySelectorAll('.split-question').forEach(function(v,k){
                answers[v.dataset['question']] = v.value
            })
            document.querySelectorAll('.city-selector').forEach(function(v,k){
                answers[v.dataset['question']] = $('.city-selector').select2('data')
            })

            console.log(answers)
            fetch("/api/answer", {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify({ 
                questionnaire_id: gon.questionnaire.id, 
                answers: answers,
              }),
            }).then(response => {
              window.location.href = url
            });

                //window.location = url
        })
    }

    if(document.querySelector('.appraise-next')){
        var nextButton = document.querySelector('.appraise-next')
        var shouldProceed = true;
        nextButton.addEventListener('click', function(){
            shouldProceed = true;
            var url = this.dataset.url
            var answers = {}
            document.querySelectorAll('.likert-slider').forEach(function(v,k){
                if(!errorShown && v.value == 4 && k == 0){
                    alert("Please reflect on your product and move the move the sliders accordingly.");
                    errorShown = true;
                    shouldProceed = false;
                    return;
                }
                answers[v.dataset['question']] = v.value
            })
            if(dataAppraise){
                if(window.selectedProblems.length < 2){
                    shouldProceed = false;
                }
            }
            if(!shouldProceed){
              return;
            }
            if(dataAppraise){
                answers = {microProblems: selectedProblems}
            }
            console.log(answers)
            fetch("/api/answer", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ 
                    questionnaire_id: gon.questionnaire.id, 
                    answers: answers,
                }),
            }).then(response => {
                $.toast({ 
                    text : "Progress Saved", 
                    showHideTransition : 'slide',
                    bgColor : '#000',    
                    textColor : '#fff',   
                    hideAfter : 1000,      
                    textAlign : 'left',     
                    position : 'bottom-right'
                })
                setTimeout(function(){
                    window.location = url
                },1000);
            });

        })
    }
}

/* Generic event handling for apply pages */

function setupApply(){
    if(document.querySelector('.apply-app')){
        console.log("Apply");
        window.applyApp = new Vue({
            el: ".apply-app",
            components: {
                goallist,
                caliradio,
            },
            data() {
                return {
                    haveList: gon.answers == null || JSON.stringify(gon.answers) == "{}" ? [{goal: ""}] : gon.answers["haveList"],
                    wantList: gon.answers == null || JSON.stringify(gon.answers) == "{}" ? [{goal: ""}] : gon.answers["wantList"],
                    microProblems: gon.answers == null || JSON.stringify(gon.answers) == "{}" ? {} : gon.answers["microProblems"],
                    microData: gon.answers == null || JSON.stringify(gon.answers) == "{}" ? {} : gon.answers["microData"],
                }
            },
            computed: {
                validAnswers() {
                    if(gon.micro_problems && gon.micro_problems.length > 0){
                        if(Object.keys(this.microProblems).length != gon.micro_problems.length){
                            return false;
                        }
                        for(var i = 0; i < Object.keys(this.microProblems).length; i++){
                            var key = Object.keys(this.microProblems)[i]
                            if(this.microProblems[key] == 'true'){
                                if(this.microData[key] == "" || this.microData[key] == undefined || this.microData[key] == null){
                                    return false;
                                }
                            }
                        }
                        return true;
                    } else {
                        return this.haveList.filter(x => x.goal != "").length > 0 && this.wantList.filter(x => x.goal != "").length > 0
                    }
                },
                answersPayload() {
                    if(gon.micro_problems){
                        return {microProblems: this.microProblems, microData: this.microData}
                    } else {
                        return {haveList: this.haveList, wantList: this.wantList}
                    }
                },
            },
            methods: {
                applyNext(e) {
                    if(this.validAnswers){
                        fetch("/api/answer", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({ 
                                questionnaire_id: gon.questionnaire.id, 
                                answers: this.answersPayload,
                            }),
                        }).then(response => {
                            console.log(e)
                            $.toast({ 
                                text : "Progress Saved", 
                                showHideTransition : 'slide',
                                bgColor : '#000',    
                                textColor : '#fff',   
                                hideAfter : 1000,      
                                textAlign : 'left',     
                                position : 'bottom-right'
                            })
                            setTimeout(function(){
                              window.location = e.target.dataset.url
                            },1000);
                        });
                    } else {
                        if(gon.micro_problems){
                          alert("Please fill in at least one reflection.")
                        } else {
                            alert("Please fill in all micro problems correctly");
                        }
                    }
                }
            },
        });
    }
}

function setupDataOnboarding(){
    if(document.querySelector('.data-onboarding-app')){
        console.log("Data Onboarding");
        window.dataOnboardingApp = new Vue({
          mounted: function(){
            if(this.currentAnswer == null) {
              if(this.currentQuestion.question_type == 'mcqm') {
                this.currentAnswer = [];
              }
            }
          },

          el: ".data-onboarding-app",
          components: {
            caliradio: caliradio,
            calicheckbox: calicheckbox
          },
          data() {
            return {
              questions: gon.questionnaire.questions,
              answers: gon.answers || [],
              currentQuestion: gon.questionnaire.questions[gon.questionIndex],
              questionIndex: gon.questionIndex,
              currentAnswer: gon.answers == null ? null : gon.answers[gon.questionnaire.questions[0].id],
            }
          },
          methods: {
            saveAttemptAndShowMessage() {
              this.saveAttempt();
              alert("Progress Saved")
            },
            saveAttempt(){
              fetch("/api/answer", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify({ 
                  questionnaire_id: gon.questionnaire.id, 
                  answers: this.answers 
                })
              }).then(response => {
                return response.json()
              }).then((data) => {
                console.log(data)
              }).catch(error => {
                console.error("error", error)
              })
            },
            prevButton(){
              console.log(this.currentAnswer)
              this.answers[this.currentQuestion.id] = this.currentAnswer
              this.saveAttempt()
              if(this.questionIndex > -1){
                this.questionIndex = this.questionIndex - 1
                this.currentQuestion = this.questions[this.questionIndex]
                this.currentAnswer = this.answers[this.currentQuestion.id];
                console.log(`Question switched, current answer is ${this.currentAnswer}`)

                /* If the answer is null, initialize accordingly */
                if(this.currentAnswer == null) {
                  if(this.currentQuestion.question_type == 'mcqm') {
                    this.currentAnswer = [];
                  }
                }
              }                 
            },
            nextButton(){
              console.log("Foobar");
              console.log(this.currentAnswer);
              if(this.currentAnswer == null || this.currentAnswer == undefined){
                return;
              }
              console.log(this.currentAnswer)
              this.answers[this.currentQuestion.id] = this.currentAnswer
              this.saveAttempt()
              if(this.questionIndex < this.questions.length - 1){
                this.questionIndex = this.questionIndex + 1
                this.currentQuestion = this.questions[this.questionIndex]
                this.currentAnswer = this.answers[this.currentQuestion.id];
                console.log(`Question switched, current answer is ${this.currentAnswer}`)

                /* If the answer is null, initialize accordingly */
                if(this.currentAnswer == null) {
                  if(this.currentQuestion.question_type == 'mcqm') {
                    this.currentAnswer = [];
                  }
                }

              } else {
                /* Depending on where we are, go to the next section */
                if(window.location.href.indexOf("data-onboarding")>-1){
                  window.location = "/demographic-analysis"
                } else {
                  window.location = "/progress/2"
                }
              }
            },
          },
        });
    }
}


function setupOnboarding(){
    if(document.querySelector('.onboarding-app')){
        console.log("Onboarding");
        window.onboardingApp = new Vue({
            el: ".onboarding-app",
            mounted: function(){
              if(this.currentAnswer == null) {
                if(this.currentQuestion.question_type == 'mcqm') {
                  this.currentAnswer = [];
                }
              }
            },
            components: {
                caliradio: caliradio,
                calicheckbox: calicheckbox
            },
            data() {
                return {
                    questions: gon.questionnaire.questions,
                    answers: gon.answers || [],
                    currentQuestion: gon.questionnaire.questions[gon.questionIndex],
                    questionIndex: gon.questionIndex,
                    currentAnswer: gon.answers == null ? null : gon.answers[Object.keys(gon.answers)[0]],
                }
            },
            watch: {
              currentAnswer(newAnswer, oldAnsweer) {
                if(this.currentQuestion.question_type == 'mcqm'){
                  console.log("Checking answer", newAnswer);
                  if(newAnswer && newAnswer.length > this.currentQuestion.max_selections) {
                    $.toast({
                      heading: `Maximum Selections Exceeded`,
                      text: `Please select up to ${this.currentQuestion.max_selections} choices only`,
                      position: 'mid-center',
                      stack: false,
                      hideAfter: 2000,
                    })
                    this.currentAnswer.pop();
                  }
                }
              }
            },

            methods: {
                saveAttempt(){
                    fetch("/api/answer", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({ 
                            questionnaire_id: gon.questionnaire.id, 
                            answers: this.answers 
                        })
                    }).then(response => {
                        return response.json()
                    }).then((data) => {
                        console.log(data)
                    }).catch(error => {
                        console.error("error", error)
                    })
                },
                prevButton(){
                    this.answers[this.currentQuestion.id] = this.currentAnswer
                    this.saveAttempt()
                    if(this.questionIndex > -1){
                        this.questionIndex = this.questionIndex - 1
                        this.currentQuestion = this.questions[this.questionIndex]
                        this.currentAnswer = this.answers[this.currentQuestion.id];
                        console.log(`Question switched, current answer is ${this.currentAnswer}`)

                        /* If the answer is null, initialize accordingly */
                        if(this.currentAnswer == null) {
                            if(this.currentQuestion.question_type == 'mcqm') {
                                this.currentAnswer = [];
                            }
                        }
                    } 
                },

                nextButton(){
                    console.log("Foobar");
                    console.log(this.currentAnswer);
                    if(this.currentAnswer == null || this.currentAnswer == undefined || (this.currentAnswer && this.currentAnswer.length == 0)){
                        return;
                    }

                    console.log(this.currentAnswer)
                    this.answers[this.currentQuestion.id] = this.currentAnswer
                    this.saveAttempt()
                    if(this.questionIndex < this.questions.length - 1){
                        this.questionIndex = this.questionIndex + 1
                        this.currentQuestion = this.questions[this.questionIndex]
                        this.currentAnswer = this.answers[this.currentQuestion.id];
                        console.log(`Question switched, current answer is ${this.currentAnswer}`)

                        /* If the answer is null, initialize accordingly */
                        if(this.currentAnswer == null) {
                            if(this.currentQuestion.question_type == 'mcqm') {
                                this.currentAnswer = [];
                            }
                        }

                        //TODO
                        //history.pushState({'question': this.questionIndex});
                    } else {
                        //TODO Fix
                        if(window.location.href.indexOf("data-onboarding")>-1){
                            window.location = "/usage/absorb"
                        } else {
                            window.location = "/progress/2"
                        }
                    }
                },
            },
        });
    }
}

function setupLensBoxes(){
    document.querySelectorAll('.lens-box').forEach(function(el){
        el.addEventListener('click', function(){
            document.querySelectorAll('.lens-box').forEach(function(el2){
                el2.classList.toggle('active');
            });
            document.querySelector('.data-copy').classList.toggle('d-none')
            document.querySelector('.besc-copy').classList.toggle('d-none')
        });
    });
}

function reportDatasets(sData){
    var datasets = [];
    var colors = ["#FF3E6C", "#000000", "#295DE3"];
    var keys = Object.keys(gon.splitScores);
    keys.forEach((key,i) => {
        datasets.push({
            label: gon.key_name_map[key],
            backgroundColor: colors[i%3],
            data: gon.splitScores[key][sData].scores,
            barThickness: 30,
            borderRadius: 3,
        });
    });
    console.log("Datasets", datasets);
    return datasets;
}

function setupCharts() {
    if($('#feedback-chart-0').length > 0){
        var scoringData = gon.scores
        gon.report_pages.forEach(function(sData,i){
            var ctx = document.getElementById('feedback-chart-' + i).getContext('2d');
            var feedbackChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: ["Goal aligned feedback","Sense of progress"],
                    datasets: reportDatasets(sData),
                },
                options: {
                    title: {
                        display: true,
                        text: ''
                    },
                    scales: {
                        y: {
                            suggestedMin: 0,
                            suggestedMax: 10,
                        }
                    }
                }
            });
        });
    }
}

function disableBack() {
    history.pushState(null, document.title, location.href);
    window.addEventListener('popstate', function (event) {
        history.pushState(null, document.title, location.href);
    });
}

document.addEventListener('turbolinks:load', () => {
    console.log("Application")
    setupLensBoxes();
    setupQuestions();
    setupFlips();
    setupCharts();
})

function setupFlips() {
    document.querySelectorAll('.flip-button').forEach(function(el){
        el.addEventListener('click', function(){
          this.parentElement.classList.toggle('flipped')
        })
    })
}


require("trix")
require("@rails/actiontext")
