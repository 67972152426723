<template>
  <div 
    class="checkbox-button"
    @click.stop.prevent="handleChange()"
  >
    <label 
      class="label" 
      :for="id"
    >
      {{ label }}
    </label>
    <input 
      :id="id"
      ref="radio"
      type="radio" 
      :value="radiovalue" 
      :name="name"
      class="boggles"
      :checked="checked"
    >
  </div>
</template>

<script>
  export default {
    props: {
      checked: {default: false, type: Boolean},
      label: {default: "", type: String},
      radiovalue: {default: null, type: String},
      name: {default: "", type: String},
      id: {default: "", type: String},
    },
    emits: ['input','change'],
    data() {
      return {
      }
    },
    computed: {
      proxyChecked: {
        get() {
          return this.checked;
        },
        set (val) {
          this.$emit("change",  val);
        }
      }
    },
    mounted: function(){
      console.log("The value of ", this.radiovalue);
      console.log("is: ", this.proxyChecked);
    },

    methods: {
      handleChange (e) {
        console.log("Handing change",new Date())
        this.toggle()
        if(this.$refs.radio.checked){
          this.$emit('input', this.radiovalue)
        }
      },
      toggle() {
        console.log("Toggle")
        this.$refs.radio.checked = true
      },
    }
  }
</script>
