<template>
  <div 
    class="checkbox-button noselect"
    @click="toggle()"
  >
    <label 
      class="label" 
      :for="id"
    >
      {{ label }}
    </label>
    <input 
      v-model="proxyChecked"
      type="checkbox" 
      :value="checkboxvalue" 
      :name="name"
      @click.stop=""
    >
  </div>
</template>

<script>
  export default {
    model: {
      prop: "checked",
      event: "change",
    },
    props: {
      checked: { type: [Array, Boolean], default: false },
      label: {default: "", type: String},
      checkboxvalue: {default: null, type: String},
      name: {default: "", type: String},
      id: {default: "", type: String},
    },
    emits: ["change"],
    computed: {
      proxyChecked: {
        get() {
          return this.checked;
        },
        set (val) { 
          this.$emit("change", val);
        },
      },
    },
    methods: {
      toggle() {
        console.log("Toggling")
        console.log(this.proxyChecked);
        if(this.proxyChecked.indexOf(this.checkboxvalue)>-1){
          this.proxyChecked = this.proxyChecked.filter(x => x!=this.checkboxvalue);
        } else {
          this.proxyChecked = [...this.proxyChecked, this.checkboxvalue];
        }
      }
    },
  }
</script>
