<template>
  <div 
    class="ranking-box"
  >
    <div class="ranking-box-title">
      {{ title }}
    </div>
    <div class="ranking-box-content">
      <div 
        v-for="(item,i) in items"
        :key="i"
        class="ranking"
      >
        <div class="d-flex align-items-center">
          <div class="ranking-number mr-3">
            <div class="ranking-number-box">
              {{ i+1 }}
            </div>
          </div>
          <div class="ranking-edit">
            <input 
              v-model="item.goal"
              class="invisible-input form-control" 
              v-bind:placeholder="placeholder" 
            >
          </div>
          <div 
            class="ranking-close" 
            @click="deleteRanking(i)"
          >
            &times;
          </div>
        </div>
      </div>
      <a 
        href="javascript:;" 
        class="btn btn-link btn-add-more"
        @click="addMore()"
      >
        + Add More
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {default: "", type: String},
      placeholder: { default: "Enter your goal", type: String },
      items: {default: () => [], type: Array},
    },
    emits: ['change'],
    computed: {
      proxyItems: {
        get() {
          return this.items;
        },
        set (val) {
          this.$emit('change',val);
        }
      },
    },
    methods: {
      addMore() {
        this.proxyItems.push({goal: ""})
      },
      deleteRanking(i) {
        if(this.proxyItems.length <= 1) {
          return;
        }
        this.proxyItems.splice(i,1)
      }
    }
  }
</script>
